/* EmailModal.css */
:root {
    --color-background: #141414; /* Background color */
    --color-text: #ffffff; /* Text color */
}

.email-modal {
    position: fixed; /* Changed from absolute to fixed */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-background);
    padding: 15px;
    padding-top: 0px; /* Reduce top padding */
    border: 2px solid #ffffff; /* White border */
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: var(--color-text);
    z-index: 10;
}

.email-modal-overlay {
    background: rgba(0, 0, 0, 0.75);
    z-index: 9;
}

.email-modal h2 {
    margin-bottom: 20px;
    color: var(--color-text);
}

.email-modal form {
    display: flex;
    flex-direction: column;
}

.email-modal label {
    margin-bottom: 10px;
    color: var(--color-text);
}

.email-modal input {
    margin-bottom: 20px;
    padding: 10px 70px;
    margin-left:10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #ffffff;
    color: var(--color-background);
}

.email-modal button {
    padding: 10px;
    right: -10px !important;
    border: none;
    border-radius: 5px;
    background: #8c52ff !important;
    color: var(--color-text);
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

.email-modal button[type="button"] {
    background: #6c757d;
}

.email-modal button:hover {
    background: #ff914d;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-background) !important;
    border: 2px solid var(--color-text);
    border-radius: 50%;
    transform: translate(-50%, 50%); /* Adjust the position to move it inward */
    color: var(--color-text);
    font-size: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.close-button:hover {
    background: var(--color-text);
    color: var(--color-background);
}

.no-spam-text {
    color: #aaaaaa;
    margin-bottom: 20px;
    margin-top: -15px;
}

@media (max-width: 480px) {
    .email-modal {
        width: 98%;
    }
}
.error-text {
    color: #ffa4a4;
    margin-top: -15px;
    margin-bottom: 10px;
}

.success-text {
    background-color: #5bae63; /* Green color */
    color: #ffffff; /* Text color for contrast */
    padding: 0.08em 0.08em; /* Padding around the text */
    border-radius: 4px; /* Rounded corners */
    text-align: center;
    margin-top: 10px;
}

.link-copy-container {
    position: relative;
  }
  
  .copy-link-tooltip {
    position: absolute;
    bottom: 22px;
    left: -10px;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 100%;
  }