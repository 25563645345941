.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: -10px;
  }
  
  .privacy-policy-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul {
    margin-bottom: 15px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 5px;
  }
  