.terms-of-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: -15px;
  }
  
  .terms-of-service-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .terms-of-service-container h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-of-service-container p,
  .terms-of-service-container ul {
    margin-bottom: 15px;
  }
  
  .terms-of-service-container li {
    margin-bottom: 5px;
  }

  