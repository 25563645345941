.articles-page {
  margin: 0 auto;
  padding: 50px;
  margin-left: 100px;
  box-sizing: content-box !important;
}

.articles-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
}

.articles-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px;
  box-sizing: content-box !important;
}

.article-card {
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 3px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-image {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.article-card-title {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.article-summary {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.read-more-link {
  background-color: #000000;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s;
}

.read-more-link:hover {
  background-color: #a357ff;
}

/* Responsive styles */
@media (max-width: 768px) {
  .article-card {
    width: 60%;
    padding:0px;
  }

  .articles-page {
    margin: 0 auto;
    padding: 20px;
    margin-left: 10px;
    margin-top:40px;
  }
}

@media (min-width: 1024px) {
  .article-card {
    width: 30%;
  }
}
/* Responsive styles */
@media (max-width: 768px) {
  .article-card {
    width: 60%; /* Adjust width as needed */
    padding: 5px;
  }

  .articles-page {
    margin: 0 auto;
    padding: 20px;
    margin-left: 10px;
    margin-top: 40px;
  }

  .articles-list {
    gap: 0px; /* Reduce gap between articles */
  }
}

@media (min-width: 1024px) {
  .article-card {
    width: 30%;
  }
}

/* Ensure the "Read More" link is positioned at the bottom of each article card */
.article-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the "Read More" link is pushed to the bottom */
  height: 100%; /* Make the card take full height for consistent layout */
}

.read-more-link {
  margin-top: auto; /* Push the link to the bottom */
}

/* Responsive styles */
@media (max-width: 768px) {
  .article-card {
    width: 45%; /* Adjust width as needed */
    padding: 5px;
  }

  .articles-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
  }
  .article-card-title {
    font-size: 16px;
    margin-bottom: 2px;
    text-align: center;
  }
  .articles-page {
    margin: 0 auto;
    padding: 20px;
    margin-left: 10px;
    margin-top: 40px;
  }

  .articles-list {
    gap: 5px; /* Reduce gap between articles */
  }
}

@media (min-width: 1024px) {
  .article-card {
    width: 20%;
  }
}

.article-card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
}

.article-last-updated {
  font-size: 0.9em;
  color: #ffffff;
  margin-top: 0.5em;
  text-align: center;
}