.compact-article-list {
  background-color: #141414;
  padding: 20px;
  margin: 20px auto;
  width: 50%;
  max-width: 600px;
}

.compact-article-list h2 {
  color: #ffffff;
  font-size: 34px;
  margin-bottom: 35px;
  text-align: center;
}

.compact-article-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.compact-article-list li {
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.compact-article-list li:last-child {
  border-bottom: none;
}

.compact-article-list a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.compact-article-list a:hover {
  text-decoration: underline;
}

.article-icon {
  width: 34px;
  height: 34px;
  margin-right: 40px;
  object-fit: cover;
  flex-shrink: 0;
}

.article-title {
  flex: 1;
  min-width: 0;
}

.article-date {
  color: #888;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 10px;
}

.see-all-link {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
}

.see-all-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .compact-article-list {
    width: 90%;
  }

  .compact-article-list a {
    flex-wrap: wrap;
  }

  .article-icon {
    margin-right: 20px;
  }

  .article-title {
    flex: 1;
    min-width: 0;
  }

  .article-date {
    width: 100%;
    margin-left: 44px;
    margin-top: 5px;
  }
}