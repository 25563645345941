.article-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: -10px;
  background-color: #141414;
  color: white;
  font-family: Arial, sans-serif;
}

.article-page h1 {
  font-size: 34px;
  margin-bottom: 10px;
}

.article-page h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.article-page p,
.article-page ul {
  margin-bottom: 15px;
}

.article-page li {
  margin-bottom: 5px;
}

.article-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.article-content {
  font-size: 16px;
  line-height: 1.6;
}

.article-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
}

.article-title {
  font-size: 50px; /* Increase the font size for the title */
  text-align: center;
  margin-bottom: 20px;
}

.article-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.article-content h2 {
  font-size: 24px;
  margin-top: 30px; /* Add more spacing between sections */
  margin-bottom: 15px;
}

.article-content p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px; /* Add more spacing between paragraphs */
}

.product-link {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  text-decoration: none !important; /* Underline on hover */
}

.product-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.product-link strong {
  font-weight: bold;
}

.highlight {
  display: inline-flex; /* Adjust display for tight fit */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 2px 6px; /* Adjust padding for snug fit */
  border-radius: 5px; /* Rounded corners for attribute boxes */
  background-color: #5bae63; /* Green background */
  color: #fff; /* White text */
  font-size: 16px; /* Font size for attributes */
  white-space: nowrap; /* Prevents wrapping of text inside the span */
}

.highlight:hover {
  background-color: #0b6c0c; /* Lighter green on hover */
}

.last-updated {
  text-align: center;
  font-size: 14px;
  margin-top: 0; /* Adjust as needed */
  margin-bottom: 10px; /* Adjust as needed */
}

/* Media query for mobile devices */


.article-image {
  max-width: 100%;
  width: 500px; /* Set a maximum width for the image */
  height: auto;
  margin: 0 auto 20px auto; /* Center the image */
  display: block;
  border-radius: 8px;
}

.see-all-link {
  display: block;
  font-size: 14px;
  color: #007bff;  /* Blue color */
  text-decoration: none;
  margin: 10px auto; /* Add some space above the link and center it */
  text-align: center;
}

.see-all-link:hover {
  text-decoration: underline;
}

.sale-text {
  color: rgb(255, 90, 90);
  font-weight: bold;
  margin-left: 5px;
}

.product-image {
  width: 16%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sale-text {
  color: rgb(255, 90, 90);
  font-weight: bold;
  margin-left: 5px;
}


@media (max-width: 600px) {
  .article-content p {
    font-size: 14px; /* Decrease font size for smaller screens */
    margin-bottom: 15px; /* Adjust spacing as needed */
  }
  .PageFooter-Socials {
    margin-bottom: 15px;
  }
  .article-title {
    font-size: 36px; /* Adjust title font size for mobile */
  }

  .article-content h2 {
    font-size: 20px; /* Adjust section header font size for mobile */
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .product-image {
    width: 30%;
  }

  .highlighted-list {
    font-size:14px;
  }
}

.notification-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #8c52ff; /* Purple background color */
  color: #ffffff; /* White text color */
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: 20px auto; /* Center the button horizontally */
  font-size: 16px; /* Adjust the font size as needed */
}

.notification-button:hover {
  background: #ff914d; /* Change background color on hover */
}

.article-content.underlined-links a {
  text-decoration: underline;
}

.highlighted-list {
  background-color: #3D3B3B;
  padding: 15px 30px 15px 30px;
  border-radius: 8px;
  margin: 15px 0;
  color: #ffffff;
  list-style-type: none; /* Remove default bullets */
}

.highlighted-list li {
  margin-bottom: 10px;
  position: relative; /* For absolute positioning of pseudo-element */
  padding-left: 20px; /* Make room for custom bullet */
}

.highlighted-list li::before {
  content: "•"; /* Unicode bullet character */
  position: absolute;
  left: 0;
  color: #ffffff; /* Match text color */
  font-size: 1.2em; /* Adjust size as needed */
  line-height: 1;
}
.article-image2 {
  max-width: 50%;
  width: 500px; /* Set a maximum width for the image */
  height: auto;
  margin: 0 auto 20px auto; /* Center the image */
  display: block;
  border-radius: 8px;
}

.references-list {
  margin-top: 20px;
  padding-left: 0px;
}

.references-list li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.references-list a {
  text-decoration: underline;
  color: inherit;
}

.references-list a:hover {
  color: #007bff;  /* Change this to match your site's color scheme */
}

.article-content.underlined-links a:hover {
  color: #007bff;  /* Change this to match your site's color scheme */
}

.greenback {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #7861a6;
    color: #fff;
    font-size: 16px;
    white-space: nowrap;
}

.text-center {
  text-align: center;
}

