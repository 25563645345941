:root {
  --color-primary: #141414;
  --color-secondary: #141414;
  --color-background: #141414;
  --color-text: #ffffff;
}

.quit-weed-page {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-left: 100px;
  width: calc(100% - 100px);
  min-height: 100vh;
  padding: 20px;
  overflow-x: hidden;
}

/* AppIntro Styles */
.quit-weed-page .CategoryIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 100%;
  margin-top: 50px;
}

.quit-weed-page .CategoryIntro-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quit-weed-page .CategoryIntro-Title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  flex: 1;
  text-align: center;
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quit-weed-page .CategoryIntro-Backdrop {
  flex: 0 0 30%;
  max-width: 30%;
  overflow: hidden;
}

.quit-weed-page .CategoryIntro-Backdrop img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px solid #000000;
  border-radius: 60px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.quit-weed-page .CategoryIntro-Description {
  font-size: 18px;
  color: #ffffff;
  opacity: 0.9;
  text-align: center;
  margin-top: 20px;
  max-width: 800px;
}

.quit-weed-page .CategoryIntro-Meta {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  margin-top: 25px;
  padding: 10px 0;
  font-style: italic;
}

.comments-count {
  background-color: #8c52ff;
  padding: 5px 5px 5px 2px;
  margin-left: 5px;
  border-radius: 3px;
  color: white;
}

.tree-background {
  background-color: #5bae63;
  color: #ffffff;
  padding: 0.08em 0.08em;
  border-radius: 4px;
}

/* Content Wrapper Styles */
.content-wrapper {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

.left-column, .right-column {
  flex: 1;
}

/* Supplement Styles */
.left-column h2, .right-column h2 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}

.supplement-info {
  background: white;
  color: var(--color-background);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.supplement-info h3 {
  font-size: 20px;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.supplement-info p {
  font-size: 16px;
  color: var(--color-secondary);
}

/* Product Grid Styles */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 20px;
}

.product-tile {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.quit-weed-page .product-tile:hover {
  transform: scale(1.05);
}

.product-tile img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Responsive Styles */
@media (max-width: 1000px) {
  .quit-weed-page {
      margin-left: 0;
      width: 100%;
  }
}

@media (max-width: 768px) {
  .quit-weed-page {
    background-color: var(--color-background);
    color: var(--color-text);
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-left: 0px !important;
    width: calc(100% - 100px);
    min-height: 100vh;
    padding: 30px !important;
    overflow-x: hidden;
  }

  .quit-weed-page .CategoryIntro-Header {
      flex-direction: column;
  }

  .quit-weed-page .CategoryIntro-Title {
      font-size: 32px;
      margin-bottom: 20px;
  }

  .quit-weed-page .CategoryIntro-Backdrop {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 20px;
  }

  .quit-weed-page .CategoryIntro-Backdrop img {
      width: 70%;
  }

  .content-wrapper {
      flex-direction: column;
  }

  .product-grid {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}

/* Additional Utility Classes */
.in-order {
  display: inline;
}

@media (max-width: 768px) {
  .in-order {
      display: block;
  }
}

/* Ensure backdrop image scales properly on larger screens */
@media (min-width: 1200px) {
  .quit-weed-page .CategoryIntro-Backdrop img {
      width: 65%;
  }
}



.in-order {
  font-size: 24px;
}


@media (max-width: 480px) {
  .in-order {
      font-size: 18px;
    }
}

.quit-weed-page {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-left: 100px;
  width: calc(100% - 100px);
  min-height: 100vh;
  padding: 20px;
  overflow-x: hidden; /* This is correct, keeping it */
}

/* Add this new rule to ensure content doesn't overflow */
.content-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}

/* Modify the existing media query */
@media (max-width: 1000px) {
  .quit-weed-page {
    margin-left: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box; /* Add this to include padding in width calculation */
  }
}

/* Additional responsive adjustments */
@media (max-width: 768px) {
  .quit-weed-page {
    padding: 10px;
  }
  
  .quit-weed-page .CategoryIntro-Backdrop img {
    width: 100%; /* Changed from 70% to ensure it doesn't overflow */
    max-width: 300px; /* Add a max-width to prevent the image from becoming too large */
  }
}

/* Ensure all images are responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Existing styles */
.quit-weed-page {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-left: 100px;
  width: calc(100% - 100px);
  min-height: 100vh;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box; /* Add this to include padding in width calculation */
}

/* Ensure content doesn't overflow on larger screens */
.content-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}

/* Adjust layout for larger screens */
@media (min-width: 1000px) {
  .quit-weed-page {
    margin-left: 0;
    width: 100%;
    padding: 20px 5%; /* Use percentage-based padding */
  }

  .content-wrapper {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap on narrower screens */
    justify-content: space-between;
    gap: 20px;
  }

  .left-column, .right-column {
    flex: 1 1 calc(50% - 10px); /* Adjust width accounting for gap */
    min-width: 300px; /* Ensure columns have a minimum width */
  }
}

/* Ensure all images are responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Adjust AppIntro for larger screens */
@media (min-width: 1000px) {
  .quit-weed-page .CategoryIntro {
    padding: 20px 5%;
  }

  .quit-weed-page .CategoryIntro-Header {
    flex-wrap: wrap;
  }

  .quit-weed-page .CategoryIntro-Title {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .quit-weed-page .CategoryIntro-Backdrop {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .quit-weed-page .CategoryIntro-Description,
  .quit-weed-page .CategoryIntro-Meta {
    flex: 1 1 100%;
    margin-top: -55px;
  }
}

/* Additional adjustments for very large screens */
@media (min-width: 1700px) {
  .quit-weed-page .CategoryIntro-Description,
  .quit-weed-page .CategoryIntro-Meta {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.quit-weed-page {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 90%;
  min-height: 100vh;
  margin-left: 100px;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.quit-weed-page .CategoryIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 100%;
  margin-top: 50px;
}

.quit-weed-page .CategoryIntro-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.quit-weed-page .CategoryIntro-Title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  flex: 1;
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quit-weed-page .CategoryIntro-Backdrop {
  flex: 0 0 30%;
  max-width: 30%;
  overflow: hidden;
}

.quit-weed-page .CategoryIntro-Backdrop img {
  width: 60%;
  height: auto;
  object-fit: cover;
  border: 1px solid #000000;
  border-radius: 60px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.content-wrapper {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.left-column, .right-column {
  flex: 1 1 calc(50% - 20px);
  min-width: 300px;
}

@media (max-width: 1000px) {
  .quit-weed-page {
    padding: 10px;
  }

  .quit-weed-page .CategoryIntro-Header {
    flex-direction: column;
  }

  .quit-weed-page .CategoryIntro-Title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .quit-weed-page .CategoryIntro-Backdrop {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }

  .quit-weed-page .CategoryIntro-Backdrop img {
    width: 70%;
    max-width: 300px;
  }
}

@media (min-width: 1001px) and (max-width: 1400px) {
  .quit-weed-page .CategoryIntro-Title {
    font-size: 28px;
  }
}

@media (min-width: 1401px) {
  .quit-weed-page .CategoryIntro-Title {
    font-size: 32px;
  }
}

/* Ensure all images are responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Adjustments for very large screens */
@media (min-width: 1700px) {
  .quit-weed-page .CategoryIntro-Description {
      font-size: 22px;
      margin-top: -85px;
  }

  .quit-weed-page .CategoryIntro-Meta {
      font-size: 18px;
      margin-top: -55px;
      margin-right: 700px;
  }
}

.quit-weed-page .supplement-info {
  background: white;
  color: var(--color-background);
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
  border: 3px solid transparent;
  border-image: linear-gradient(90deg, #8c52ff, #ff914d) 1;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.quit-weed-page .supplement-info h3 {
  font-size: 20px;
  color: var(--color-primary);
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.quit-weed-page .supplement-info p {
  font-size: 16px;
  color: var(--color-secondary);
  width: 100%;
  text-align: left;
}

/* Adjust for dark background if needed */
.quit-weed-page .supplement-info h3,
.quit-weed-page .supplement-info p {
  color: var(--color-background);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quit-weed-page .supplement-info {
    padding: 10px;
  }
  
  .quit-weed-page .supplement-info h3 {
    font-size: 18px;
  }
  
  .quit-weed-page .supplement-info p {
    font-size: 14px;
  }
}


.quit-weed-page .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

h4 {
  font-size: 20px;
  margin-top: 5px;
}

.quit-weed-page .product-tile {
  position: relative;
  background: white;
  padding: 10px;
  border: 3px solid transparent;
  border-image: linear-gradient(90deg, #8c52ff, #ff914d) 1;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quit-weed-page .product-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.quit-weed-page .product-tile img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.quit-weed-page .product-tile .product-name {
  font-size: 14px;
  color: var(--color-primary);
  text-align: center;
  margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
  }

  .quit-weed-page .product-tile {
    padding: 8px;
  }

  .quit-weed-page .product-tile .product-name {
    font-size: 12px;
  }
}
.quit-weed-page .left-column h3 {
  font-size: 24px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ff914d;
  padding-bottom: 10px;
}

.quit-weed-page .content-wrapper {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

.quit-weed-page .left-column,
.quit-weed-page .right-column {
  flex: 1;
}

.quit-weed-page .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .quit-weed-page .content-wrapper {
    flex-direction: column;
  }
  
  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.product-info-icon {
  display: none;
}

.link-copy-icon {
  display: none;
}

/* Existing styles... */

.quit-weed-page .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.quit-weed-page .product-tile {
  background: white;
  border: 3px solid transparent;
  border-image: linear-gradient(90deg, #8c52ff, #ff914d) 1;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quit-weed-page .product-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.quit-weed-page .product-tile a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.quit-weed-page .product-image-container {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.quit-weed-page .product-tile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.quit-weed-page .product-info {
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

.quit-weed-page .product-tile h3 {
  font-size: 16px;
  margin: 10px 0 5px;
  color: #333;
}

.quit-weed-page .product-price {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .quit-weed-page .product-tile {
    padding: 5px;
  }

  .quit-weed-page .product-image-container {
    height: 150px;
  }

  .quit-weed-page .product-tile h3 {
    font-size: 14px;
  }

  .quit-weed-page .product-price {
    font-size: 12px;
  }
}

.quit-weed-page .compact-article-list {
  margin-top: -20px;
  width: 100% !important;
}

/* Existing styles... */

.quit-weed-page .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.quit-weed-page .product-category-title {
  grid-column: 1 / -1;
  font-size: 24px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ff914d;
  padding-bottom: 10px;
}

.quit-weed-page .product-tile {
  background: white;
  border: 3px solid transparent;
  border-image: linear-gradient(90deg, #8c52ff, #ff914d) 1;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* ... (keep other existing styles) */

@media (max-width: 768px) {
  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .quit-weed-page .product-category-title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  /* ... (keep other existing responsive styles) */
}

.quit-weed-page .compact-article-list {
  margin-top: -20px;
  width: 100% !important;
}

/* Existing styles... */

.quit-weed-page .right-column h2 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}

.quit-weed-page .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 0 20px; /* Remove top padding */
}

.quit-weed-page .product-category-title {
  grid-column: 1 / -1;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  border-bottom: 2px solid #ff914d;
  padding-bottom: 10px;
}

/* Adjust the first product category title */
.quit-weed-page .product-grid .product-category-title:first-of-type {
  margin-top: 0; /* Remove top margin for the first category title */
}

.quit-weed-page .product-category-title:not(:first-of-type) {
  margin-top: 30px; /* Keep space for subsequent category titles */
}

.quit-weed-page .product-tile {
  background: white;
  border: 3px solid transparent;
  border-image: linear-gradient(90deg, #8c52ff, #ff914d) 1;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* ... (keep other existing styles) */

@media (max-width: 768px) {
  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .quit-weed-page .product-category-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .quit-weed-page .product-category-title:not(:first-of-type) {
    margin-top: 20px;
  }

  /* ... (keep other existing responsive styles) */
}

.quit-weed-page .compact-article-list {
  margin-top: 45px;
  width: 100% !important;
  padding: 0px;
}

/* Ensure consistent spacing in the left column */
.quit-weed-page .left-column h2 {
  margin-bottom: 20px;
}

.quit-weed-page .left-column h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  border-bottom: 2px solid #ff914d;
  padding-bottom: 10px;
}

/* Remove top margin from the first h3 in the left column */
.quit-weed-page .left-column h3:first-of-type {
  margin-top: 0;
}
@media (max-width: 768px) {
  .quit-weed-page {
    margin-left: 0;
    width: 100%;
    padding: 10px;
  }

  .quit-weed-page .content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .quit-weed-page .left-column,
  .quit-weed-page .right-column {
    width: 100%;
  }

  .quit-weed-page .CategoryIntro {
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: -30px;
  }

  .quit-weed-page .CategoryIntro-Title {
    font-size: 24px;
    margin-bottom: -30px !important;
  }

  .quit-weed-page .CategoryIntro-Backdrop img {
    width: 100%;
    max-width: 200px;
  }

  .quit-weed-page .CategoryIntro-Description,
  .quit-weed-page .CategoryIntro-Meta {
    font-size: 16px;
    margin-top: 10px;
  }

  .quit-weed-page .supplement-info {
    padding: 10px;
  }

  .quit-weed-page .supplement-info h4 {
    font-size: 18px;
  }

  .quit-weed-page .supplement-info p {
    font-size: 14px;
  }

  .quit-weed-page .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    padding: 10px;
  }

  .quit-weed-page .product-tile {
    padding: 8px;
  }

  .quit-weed-page .product-tile .product-image-container {
    width: 100%;
    height: 120px;
  }

  .quit-weed-page .product-tile img {
    max-height: 120px;
  }

  .quit-weed-page .product-tile h3 {
    font-size: 14px;
    margin-top: 5px;
  }

  .quit-weed-page .product-tile .product-price {
    font-size: 12px;
  }

  .quit-weed-page .product-tile .product-attributes {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
  }

  .quit-weed-page .product-tile .attribute {
    font-size: 10px;
    padding: 2px 4px;
    margin: 2px;
  }
}

/* Existing styles ... */

@media (max-width: 768px) {
  .quit-weed-page {
    margin-left: 0;
    width: 100%;
    padding: 10px;
  }

  .quit-weed-page .content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .quit-weed-page .left-column,
  .quit-weed-page .right-column {
    width: 100%;
  }

  .quit-weed-page .supplement-section {
    margin-bottom: 30px;
  }

  .quit-weed-page .mobile-product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    margin-top: 20px;
  }

  .quit-weed-page .desktop-product-grid {
    display: none;
  }

  .quit-weed-page .compact-article-list h2 {
    display: block !important;
    color: white;
  }

  .quit-weed-page .product-tile {
    padding: 8px;
  }

  .quit-weed-page .product-name {
    color: #000000;
  }

  .quit-weed-page .product-tile .product-image-container {
    width: 100%;
    height: 120px;
  }

  .quit-weed-page .product-tile img {
    max-height: 120px;
  }

  .quit-weed-page .product-tile h3 {
    font-size: 14px;
    margin-top: 5px;
  }

  .quit-weed-page .product-tile .product-price {
    font-size: 12px;
  }

  .quit-weed-page .right-column h2 {
    display: none;
  }
}

@media (min-width: 769px) {
  .quit-weed-page .mobile-product-grid {
    display: none;
  }

  .quit-weed-page .desktop-product-grid {
    display: grid;
  }
}

/* Other existing styles ... */